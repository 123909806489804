import { templateAvailableSeats } from '../templates/available-seats';
import { templateOpenFromTo } from '../templates/open-from-to';
import type { MrDialog } from '@mrhenry/wp--mr-interactive';
import { templateOccupancyStudy360 } from '../templates/occupancy-study360';
import { getIndicatorColorForLocation } from './location-status-indication-color';

type langOption = 'nl' | 'en';
const lang : langOption = ( ( document.documentElement.getAttribute( 'lang' ) ?? 'nl' ) as langOption );
let didShowErrorDialog = false;

let init = async() => {
	const list = document.querySelectorAll( '[archive-location-id]' );
	if ( !list || !list.length ) {
		return;
	}

	init = async() => {
		/* noop */
	};

	await updateView( );
	setInterval( async() => {
		await updateView();
	}, 31 * 1000 );
};

async function updateView() {
	const location_ids = ( Array.from( document.querySelectorAll( '[archive-location-id]' ) ) as Array<HTMLElement> ).map( ( el ) => {
		// If this is not set it is a fatal error.

		return el.getAttribute( 'archive-location-id' )!;
	} );

	await fetch( 'https://study360-api.mrhenry.eu/graphql', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify( {
			query: `query q ($location_ids: [ID!]!) {
				currentOccupationStudy360 {
					locations
					visitors
				}
				locations(ids: $location_ids) {
					id
					name
					currentOpeningTimeToday {
						capacity
						closingTime
						openingTime
					}
					nextOpeningTimeToday {
						capacity
						closingTime
						openingTime
					}
					currentOccupation
					atMaximumCapacity
					isCurrentlyOpen
				additionalInformation(language: EN)
				}
			}
			`,
			variables: {
				location_ids: location_ids,
			},
		} ),
	} ).then( ( response ) => {
		return response.json();
	} ).then( ( data ) => {
		if ( !data || !( data.data?.locations?.length ) ) {
			return;
		}

		const list = Array.from( document.querySelectorAll( '[archive-location-id]' ) ) as Array<HTMLElement>;
		list.forEach( ( el ) => {
			// We selected elements with this attribute, if the attribute isn't here we want an exception.

			const id = el.getAttribute( 'archive-location-id' )!;
			const location = data.data.locations.find( ( x: {id: string} ) => {
				return x.id === id;
			} );
			if ( !location ) {
				return;
			}

			const parentEl = el.parentElement;
			if ( parentEl ) {
				parentEl.setAttribute( 'data-color', getIndicatorColorForLocation( location ) );
			}

			if ( location.isCurrentlyOpen && location.currentOpeningTimeToday ) {
				el.setAttribute( 'location-is-currently-open', 'location-is-currently-open' );

				if ( location.currentOpeningTimeToday.capacity > location.currentOccupation ) {
					el.setAttribute( 'location-has-capacity', 'location-has-capacity' );
				}
			}

			const sortable = el.closest( '[sortable]' );
			if ( sortable ) {
				sortable.setAttribute( 'sort-by-value', '-1' );
			}

			const closedAction = el.querySelector( '[list-item-template^="archive--closed--"]' );
			if ( closedAction ) {
				if ( ( !location.currentOpeningTimeToday && !location.nextOpeningTimeToday ) ) {
					closedAction.removeAttribute( 'hidden' );
				} else {
					closedAction.setAttribute( 'hidden', '' );
				}
			}

			const openAction = el.querySelector( '[list-item-template^="archive--open--"]' );
			const openActionHours = el.querySelector( '[list-item-template^="archive--open--hours--"]' );
			if ( openAction && openActionHours ) {
				if ( location.currentOpeningTimeToday || location.nextOpeningTimeToday ) {
					openAction.removeAttribute( 'hidden' );

					const slot = location.currentOpeningTimeToday ?? location.nextOpeningTimeToday;
					openActionHours.innerHTML = templateOpenFromTo( lang, slot.openingTime, slot.closingTime );
				} else {
					openAction.setAttribute( 'hidden', '' );
					openActionHours.innerHTML = '';
				}
			}

			const seatsAction = el.querySelector( '[list-item-template^="archive--available-seats--"]' );
			const seatsActionCounter = el.querySelector( '[list-item-template^="archive--available-seats--counter--"]' );
			if ( seatsAction && seatsActionCounter ) {
				if ( location.currentOpeningTimeToday ) {
					let availableSeats = Math.max( 0, location.currentOpeningTimeToday.capacity - location.currentOccupation );
					if ( location.atMaximumCapacity ) {
						availableSeats = 0;
					}

					if ( sortable ) {
						sortable.setAttribute( 'sort-by-value', availableSeats.toString() );
					}

					seatsAction.removeAttribute( 'hidden' );
					seatsActionCounter.innerHTML = templateAvailableSeats( lang, availableSeats );
				} else {
					seatsAction.setAttribute( 'hidden', '' );
					seatsActionCounter.innerHTML = '';
				}
			}

			if ( 0 < data.data?.currentOccupationStudy360?.visitors ) {
				const currentOccupationEl = document.getElementById( 'doormat-check-in-count-students' );
				if ( currentOccupationEl ) {
					currentOccupationEl.innerHTML = templateOccupancyStudy360( lang, data.data.currentOccupationStudy360.locations ?? 1, data.data.currentOccupationStudy360.visitors );
				}
			}
		} );

	} ).catch( ( err ) => {
		console.warn( err );

		if ( !didShowErrorDialog ) {
			didShowErrorDialog = true;
			( document.getElementById( 'location-data-error-dialog' ) as MrDialog | null )?.updateState( 'open' );
		}
	} );
}

init();

requestAnimationFrame( () => {
	init();
} );

document.addEventListener( 'readystatechange', () => {
	init();
} );
